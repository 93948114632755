import React, { Component } from 'react';
import { Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../css/NavMenu.css';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PieChartRoundedIcon from '@material-ui/icons/PieChartRounded'; 
import FolderRoundedIcon from '@material-ui/icons/FolderRounded';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this)
    this.state = {
      collapsed: true,
      selectedNavItem: 'budgetresults',
      dropdownOpen: false
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  handleChange(id) {
    this.setState({selectedNavItem: id})
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  logout()
  {
    this.props.logout()
  }


  render () {
    return (
      <header>
         <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <div><NavbarBrand className="brand-name" tag={Link} to="/">Caliper</NavbarBrand>
            <NavbarText className="sub-headline" to="/">Investment Optimization & Forecasting</NavbarText></div>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse center-align-flex" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} id={"budgetresults"} onClick={() => this.handleChange("budgetresults")} className={"budgetresults" === this.state.selectedNavItem ? "active" : "inactive"} to="/"><PieChartRoundedIcon></PieChartRoundedIcon>Budget Results</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} id={"docs"} onClick={() => this.handleChange("docs")} className={"docs" === this.state.selectedNavItem ? "active" : "inactive"} to="/docs"><FolderRoundedIcon></FolderRoundedIcon>Docs</NavLink>
                </NavItem>
              </ul>
            </Collapse>
            <Dropdown className='d-sm-inline-flex flex-sm-row-reverse center-align-flex margin-right' isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle className='nav-menu-icon btn-primary' caret><AccountCircleSharpIcon></AccountCircleSharpIcon></DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={this.logout}>Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Container>
        </Navbar>
     </header>
    );
  }
}
