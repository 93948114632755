import { Checkbox } from '@material-ui/core';
import React, { Component } from 'react';
import {Container, NavbarBrand, NavbarText, Navbar } from 'reactstrap';
import '../css/main.css';
import loginImage from '../images/pabst-login-unsplash.jpg';
import Cookies from 'universal-cookie';
import LearnMore from './LearnMore';
import {getApiData, getAuthToken} from '../FetchApi';

var cookies = new Cookies();
export class Login extends Component {
  
  constructor (props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isRememberMeChecked: false,
      isForgotPasswordSelected: false,
      isEmailValid: true,
      isLearnMoreSelected: false,
      isLoginWrong: false
    };
  }

  componentDidMount = () =>
  {
    if (cookies.get('remember') !== undefined)
    {
        this.props.createLoginSession(true, '', '', cookies.get('token'))
    }
  }

  displayForgotPasswordForm = () =>
  {
      this.setState({isForgotPasswordSelected: true})
  }

  formSubmit = async () =>
  { 
    var apiAuthToken = await getAuthToken("GET_AUTH_TOKEN", [this.state.username, this.state.password])
    if (apiAuthToken.token)
    {
        cookies.set('token', apiAuthToken.token, { path: '/', expires: new Date(Date.now()+2592000 * 30) });
        if (this.state.isRememberMeChecked)
        {
            cookies.set('remember', 'True', { path: '/', expires: new Date(Date.now()+2592000 * 30) });
        }
        this.props.createLoginSession(true, "", "", apiAuthToken.token)
    }

    else
    {
        this.props.createLoginSession(false, null, null, null)
        this.setState({isLoginWrong : true})
    }
  }

  setUsername = (e) =>
  {
    // if(e.target.value.length > 0)
    // {
    //     let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //     if ( re.test(e.target.value) ) {
    //         this.setState({username: e.target.value, isEmailValid:true})
    //     }
    //     else {
    //         this.setState({isEmailValid: false})
    //     }
    // } 

    this.setState({username: e.target.value, isEmailValid:true})
  }

  setPassword = (e) =>
  {
    this.setState({password: e.target.value})
  }

  setRememberMe = (e) =>
  {
      this.setState({isRememberMeChecked: e.target.checked})
  }

  submitPasswordReset = () =>
  {

  }

  learnMore = () =>
  {
      this.setState({isLearnMoreSelected: true})
  }

  backToLogin = () =>
  {
    this.setState({isForgotPasswordSelected: false, isLearnMoreSelected: false})
  }

  render () {
    let { isEmailValid, isLoginWrong, isForgotPasswordSelected, isLearnMoreSelected } = this.state;
    return (
        <>
        {isLearnMoreSelected ?
            <LearnMore backToLogin={this.backToLogin}></LearnMore>
        :
        <div className='center'>
        <Navbar className='login-page-top-bar'>
            <Container>
                <div>
                    <NavbarBrand className="brand-name">Caliper</NavbarBrand><br />
                    <NavbarText className="sub-headline">Investment Optimization & Forecasting</NavbarText>
                </div>
                <NavbarText onClick={this.learnMore} className='learn-more-text margin-right'>Learn More</NavbarText>
            </Container>
        </Navbar> <br />
        <Container>
            <div className='row'>
                <div className='col-md-6'><img className='login-image' src={loginImage} /></div>
                {isForgotPasswordSelected ? 
                <div className='col-md-6 login-form'>
                    <div className='padding'><input onBlur={this.setUsername} className='form-textbox' type="email" placeholder='Email' name="email"></input> </div>
                    <div className='validation-error' style={{display: isEmailValid ? 'none' : 'block'}}>Please enter a valid email</div>
                    <div className='padding'><button onClick={this.submitPasswordReset} className='form-button'>Reset Password</button></div> 
                    <div className='padding'><button onClick={this.backToLogin} className='form-button'>Cancel</button></div>
                </div>
                :
                <div className='col-md-6 login-form'>
                    <div className="form-headers">Username</div>
                    <div className='padding'><input onBlur={this.setUsername} className='form-textbox' type="email" placeholder='Email' name="email"></input> </div>
                    <div className='validation-error' style={{display: isEmailValid ? 'none' : 'block'}}>Please enter a valid email</div>
                    <div className="form-headers">Password</div>
                    <div className='padding'><input onChange={this.setPassword} className='form-textbox' type="password" placeholder='Password' name="password"></input></div>
                    <div className='padding'><button onClick={this.formSubmit} className='form-button'>Sign In</button></div> 
                    <div className='validation-error' style={{display: isLoginWrong ? 'block' : 'none' }}>Email or password is incorrect</div>
                    <div className='padding form-div-width center-text'><label className='form-checkbox'><input onChange={this.setRememberMe} type="checkbox" /> Remember Me</label></div>
                    <div className='padding form-div-width center-text'><a className='forgot-password' href="#" onClick={this.displayForgotPasswordForm}>Forgot Password?</a></div>
                    <div className='padding form-div-width center-text'><label className='form-checkbox'>Don't have an account?</label> <a href="#">Contact Us</a></div>
                </div> 
                }
            </div>
        </Container> <br />
        <Container>
            <div className='row' id="login-text">
                <div className='center-text col-md-12'>
                    <span className='login-page-title-text'>Digital-First Media Measurement Platform</span>
                </div>
                <div className='homepage-text-column'>
                    <div> 
                        <p className='left-side-login-text'>Rapid turnaround - Our focus on digital channels allows for the use of granular, 
                        API-sourced data, streamlining readout generation.
                        </p> 
                    </div>
                   <br />
                    <div>
                        <p className='left-side-login-text'>Granular analysis - The utilization of pixel tracked data, allows 
                            for thorough cross-channel and within channel contribution estimates.
                        </p>
                    </div>
                </div>
                <div className='homepage-text-column'>
                    <div>
                        <p className='right-side-login-text'>Indirect effects modeling - Models incorporate indirect effects, 
                        providing a more accurate measurement of upper-funnel media.
                        </p>
                    </div> <br />
                    <div>
                        <p className='right-side-login-text'>Transparency - We believe communication of modeling techniques 
                            and assumptions is essential for informed media mix decisions.
                        </p>
                    </div>
                </div>
            </div>
        </Container>
        </div>}</>
         
    );
  }
}
export default Login